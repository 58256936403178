import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
// import Cookie from "../../../utils/cookie";
// import HeaderNewLandingPage from "../HeaderNewLandingPage/HeaderNewLandingPage";
import {Alert, Button, Form, FormGroup} from "react-bootstrap";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
// import FooterNewLandingPage from "../FooterNewLandingPage/FooterNewLandingPage";
import companyLogo from "./company-logo.svg";
// import "./PartnershipPage.css";
// import HeaderPartnerPage from "../HeaderNewLandingPage/HeaderPartnerPage";
import { IoMdMenu } from "react-icons/io";
import ic_users from "../../assets/images/ic_users.png";
import Serive_icon from "../../assets/images/service_planning.png";
import Wishes_icon from "../../assets/images/Wishes_icon.jpg";
const agentClientAssignment = () => {
    // const history = useHistory();
    // const [username, setUsername] = useState(localStorage.getItem('email') !== null ? localStorage.getItem('email') : '');
    // const [password, setPassword] = useState();
    // const [companyId, setCompanyId] = useState();
    // const [loginError, setEmailError] = useState(false);
    // const [errormessage, setErrorMessage] = useState("");
    // const [sDeviceID, setCurrentIp] = useState(null);
    // const [hidden, setHidden] = useState(true);
    // // const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("token"));
    // const [isLoggedIn, setIsLoggedIn] = useState(true);
    // const inputRef = useRef();
    // const isFormValid=username && password && companyId;
    // useEffect(() => {
    //     getCurrentIp();
    //     if (inputRef.current) {
    //         inputRef.current.focus();
    //     }
    //     setErrorMessage("Wrong username/password");
    // }, []);
    //
    // const submitClickBtn = (e) => {
    //     e.preventDefault();
    //     const data = {
    //         email: username,
    //         password: password,
    //         sDeviceID,
    //         sDeviceType: "WEB",
    //     };
    //     axios.post(process.env.REACT_APP_SERVER + "auth/login", data, {
    //         headers: {"Content-Type": "application/json",},
    //     }).then((res) => {
    //         Cookie.save("isFirstTime","1");
    //         document.cookie = `serviceplanid=${
    //             res.data.users.serviceplanid
    //         }; expires=${new Date(Date.now() + 10800000).toUTCString()}; path=/`;
    //         localStorage.setItem("dashboardLayout", res.data.users.checkinbuttononly);
    //         localStorage.setItem("token", res.data.token);
    //         localStorage.setItem("login", true);
    //         localStorage.setItem("userid", res.data.users.id);
    //         localStorage.setItem("email", res.data.users.email);
    //         localStorage.setItem("name", res.data.users.fname + ' ' + res.data.users.lname);
    //         localStorage.setItem("isLogin", 1);
    //         // const user = res.data.users
    //         // if (user.newuser === 1 && user.twofactorauth === 1) {
    //         //     localStorage.setItem('isAuthenticatedOTP', '0');
    //         //     localStorage.setItem('dashboardLayout', 0);
    //         // } else if (user.newuser === 1) {
    //         //     localStorage.setItem('isBtnClicked', false)
    //         //     localStorage.setItem('isAuthenticatedOTP', '1');
    //         //     localStorage.setItem('dashboardLayout', 1);
    //         // } else {
    //         //     localStorage.setItem('isAuthenticatedOTP', res.data.users.twofactorauth === 0 ? '1' : '0');
    //         // }
    //         // localStorage.setItem('servicePlanAttempts', 0);
    //         // history.push("/maindashboard");
    //         setIsLoggedIn(true);
    //     }).catch(() => {
    //         setEmailError(true);
    //         setErrorMessage("Wrong Username/Password");
    //     });
    // };
    //
    // const getCurrentIp = () => {
    //     axios.get("https://geolocation-db.com/json/")
    //         .then((res) => {
    //             setCurrentIp(res.data.IPv4);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    // };

    return (
        <>
            {/*<HeaderNewLandingPage/>*/}
            {/*<HeaderPartnerPage/>*/}
            <div className="bg-primary text-white d-flex align-items-center justify-content-center"
                 style={{height: '50px', width: '100%'}}>
                Agents/Clients
            </div>
            {/*<Link to="/" className="flex font-bold text-black flex-col items-center" style={{marginleft: "20px"}}>*/}
            {/*    <img src={companyLogo} style={{width: '90px', height: 'auto', marginLeft: '40px', marginTop: '20px'}}*/}
            {/*         alt="Company Logo"/>*/}
            <IoMdMenu className="primary"
                      style={{width: '40px', height: '40px', marginLeft: '40px', marginTop: '20px'}}/>
            {/*</Link>*/}
            {/*<div className="inroduction_heading">*/}

            {/*<div className=" text-center fs-3 fw-bold " style={{fontSize: '1.75rem', fontWeight: 'bold'}}>*/}
            {/*    Agents' Name*/}
            {/*</div>*/}

            {/*<div className="d-flex justify-content-center mt-3">*/}
            {/*    <img src={companyLogo} alt="Company Logo" style={{width: '90px', height: 'auto'}}/>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*{!isLoggedIn ? (<>*/}
            {/*        <div className=" d-flex justify-content-center align-items-center min-vh-100 mr-4 ">*/}

            {/*            <div className="signup_form login_form w-100 " style={{ maxWidth: '400px', width: '100%' }}>*/}
            {/*                <div className=" form_content " style={{marginRight:'-15px',marginTop:'-300px'}}>*/}
            {/*                    <h2 className="form_title text-center">Login</h2>*/}
            {/*                    <Form action="" onSubmit={submitClickBtn}>*/}
            {/*                        {loginError && (*/}
            {/*                            <Alert key="wrong-login" variant="danger">*/}
            {/*                                {errormessage}*/}
            {/*                            </Alert>*/}
            {/*                        )}*/}
            {/*                        <FormGroup controlId="exampleForm.ControlInput3">*/}
            {/*                            <Form.Control*/}
            {/*                                ref={inputRef}*/}
            {/*                                type="text"*/}
            {/*                                placeholder="Company ID"*/}
            {/*                                onChange={(e) => setCompanyId(e.target.value)}*/}
            {/*                                required*/}
            {/*                            />*/}
            {/*                        </FormGroup>*/}
            {/*                        <FormGroup controlId="exampleForm.ControlInput3">*/}
            {/*                            <Form.Control*/}
            {/*                                ref={inputRef}*/}
            {/*                                type="email"*/}
            {/*                                placeholder="Email Address"*/}
            {/*                                onChange={(e) => setUsername(e.target.value)}*/}
            {/*                                required*/}
            {/*                            />*/}
            {/*                        </FormGroup>*/}

            {/*                        <FormGroup controlId="exampleForm.ControlInput3"*/}
            {/*                                   className="position-relative">*/}
            {/*                            <Form.Control*/}
            {/*                                placeholder="Password"*/}
            {/*                                type={hidden ? "password" : "text"}*/}
            {/*                                onChange={(e) => setPassword(e.target.value)}*/}
            {/*                                required*/}
            {/*                            />*/}
            {/*                            {hidden ? (*/}
            {/*                                <VisibilityOffIcon className="pass_show"*/}
            {/*                                                   onClick={() => setHidden(!hidden)}/>*/}
            {/*                            ) : (*/}
            {/*                                <VisibilityIcon className="pass_show"*/}
            {/*                                                onClick={() => setHidden(!hidden)}/>*/}
            {/*                            )}*/}
            {/*                        </FormGroup>*/}

            {/*                        <p>*/}
            {/*                            <Link to={'/new-password'}>Forgot Password?</Link>*/}
            {/*                        </p>*/}

            {/*                        <Button*/}
            {/*                            variant="primary"*/}
            {/*                            disabled={!isFormValid}*/}
            {/*                            type="submit"*/}
            {/*                            className="btn-lg btn-block custom-btn"*/}
            {/*                        >*/}
            {/*                            <span>Login</span>*/}
            {/*                        </Button>*/}
            {/*                    </Form>*/}
            {/*                </div>*/}
            {/*            </div>*/}

            {/*        </div>*/}

            {/*    </>*/}
            {/*) : (*/}
            {/*<div className="d-flex flex-column align-items-start">*/}
            {/*    /!* Button *!/*/}
            {/*    <Button*/}
            {/*        variant="light"*/}
            {/*        size="lg"*/}
            {/*        className="w-25 px-2 py-1 my-3 d-flex align-items-center justify-content-center border rounded"*/}
            {/*        style={{backgroundColor: 'white', borderColor: 'gray', color: 'black'}}*/}
            {/*    >*/}
            {/*        <img*/}
            {/*            src="/path/to/agents-icon.png"*/}
            {/*            alt="Agents"*/}
            {/*            className="me-2 rounded-circle"*/}
            {/*            style={{width: '20px', height: '20px', borderRadius: '50%'}}*/}
            {/*        />*/}
            {/*        Agents*/}
            {/*    </Button>*/}

            {/*    /!* Table Container *!/*/}
            {/*    <div*/}
            {/*        className="py-3 pb-10 mt-2 bg-white border rounded d-flex flex-column align-items-center"*/}
            {/*        style={{*/}
            {/*            borderColor: 'gray',*/}
            {/*            maxWidth: '500px',*/}
            {/*            width: '100%',*/}
            {/*            marginLeft: '10px',*/}
            {/*            marginBottom: '50px',*/}
            {/*            boxShadow: '0 0px 40px rgba(141, 177, 184, 1)',*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        <table className="table w-100" style={{borderCollapse: 'collapse', border: 'none'}}>*/}
            {/*            <thead>*/}
            {/*            <tr>*/}
            {/*                <th style={{border: 'none'}}>Agents Name</th>*/}
            {/*                <th style={{border: 'none'}}>#Clients</th>*/}
            {/*            </tr>*/}
            {/*            </thead>*/}
            {/*            <tbody>*/}
            {/*            <tr>*/}
            {/*                <td style={{border: 'none'}}>John Doe</td>*/}
            {/*                <td style={{border: 'none'}}>10</td>*/}
            {/*            </tr>*/}
            {/*            <tr>*/}
            {/*                <td style={{border: 'none'}}>Jane Smith</td>*/}
            {/*                <td style={{border: 'none'}}>15</td>*/}
            {/*            </tr>*/}
            {/*            <tr>*/}
            {/*                <td style={{border: 'none'}}>Alice Johnson</td>*/}
            {/*                <td style={{border: 'none'}}>8</td>*/}
            {/*            </tr>*/}
            {/*            <tr>*/}
            {/*                <td style={{border: 'none'}}>Bob Brown</td>*/}
            {/*                <td style={{border: 'none'}}>12</td>*/}
            {/*            </tr>*/}
            {/*            <tr>*/}
            {/*                <td style={{border: 'none'}}>Charlie Davis</td>*/}
            {/*                <td style={{border: 'none'}}>7</td>*/}
            {/*            </tr>*/}
            {/*            </tbody>*/}
            {/*        </table>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <div className="d-flex flex-sm-nowrap flex-wrap" style={{width: '100%', padding: '0 20px', gap: '20px'}}>
                {/* Left Container */}
                <div className="w-100">
                    {/* Button */}
                    <div
                        className="w-100 gap-2 p-3 px-2 py-1 my-3 d-flex align-items-center justify-content-start border rounded"
                        style={{backgroundColor: 'white', borderColor: 'gray', color: 'black'}}
                    >
                        <img
                            src={ic_users}
                            alt="Agents"
                            className="me-2 rounded-circle"
                            style={{width: '40px', height: '40px', borderRadius: '50%'}}
                        />
                        Agents
                    </div>

                    {/* Table Container */}
                    <div
                        className=" pb-10 mt-2 bg-white border rounded"
                        style={{
                            borderColor: 'gray',
                            width: '100%',
                            marginBottom: '50px',
                            boxShadow: '0 0px 40px rgba(141, 177, 184, 1)',
                        }}
                    >
                        <table className="table w-100" style={{borderCollapse: 'collapse', border: 'none'}}>
                            <thead>
                            <tr style={{border: "none"}}>
                                <th style={{border: 'none'}}>Agents Name</th>
                                <th style={{border: 'none'}}>#Clients</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr style={{border: "none"}}>
                                <td style={{border: 'none'}}>John Doe</td>
                                <td style={{border: 'none'}}>10</td>
                            </tr>
                            <tr style={{border: "none"}}>
                                <td style={{border: 'none'}}>Jane Smith</td>
                                <td style={{border: 'none'}}>15</td>
                            </tr>
                            <tr style={{border: "none"}}>
                                <td style={{border: 'none'}}>Alice Johnson</td>
                                <td style={{border: 'none'}}>8</td>
                            </tr>
                            <tr style={{border: "none"}}>
                                <td style={{border: 'none'}}>Bob Brown</td>
                                <td style={{border: 'none'}}>12</td>
                            </tr>
                            <tr style={{border: "none"}}>
                                <td style={{border: 'none'}}>Charlie Davis</td>
                                <td style={{border: 'none'}}>7</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* Right Container */}
                <div className="w-100">
                    {/* Button */}
                    <div
                        className="px-2 gap-2 p-3 w-100 py-1 my-3 d-flex align-items-center justify-content-start border rounded"
                        style={{backgroundColor: 'white', borderColor: 'gray', color: 'black'}}
                    >
                        <img
                            src={Wishes_icon}
                            alt="Agents"
                            className="me-2 rounded-circle"
                            style={{width: '40px', height: '40px', borderRadius: '50%'}}
                        />
                        Clients
                    </div>

                    {/* Table Container */}
                    <div
                        className=" pb-10 mt-2 bg-white border rounded"
                        style={{
                            borderColor: 'gray',
                            width: '100%',
                            marginBottom: '50px',
                            boxShadow: '0 0px 40px rgba(141, 177, 184, 1)',
                        }}
                    >
                        <table className="table w-100" style={{borderCollapse: 'collapse', border: 'none'}}>
                            <thead>
                            <tr style={{border: "none"}}>
                                <th style={{border: 'none'}}>First Name</th>
                                <th style={{border: 'none'}}>Last Name</th>
                                <th style={{border: 'none'}}>Account#</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr style={{border: "none"}}>
                                <td style={{border: 'none'}}>John Doe</td>
                                <td style={{border: 'none'}}>10</td>
                            </tr>
                            <tr style={{border: "none"}}>
                                <td style={{border: 'none'}}>Jane Smith</td>
                                <td style={{border: 'none'}}>15</td>
                            </tr>
                            <tr style={{border: "none"}}>
                                <td style={{border: 'none'}}>Alice Johnson</td>
                                <td style={{border: 'none'}}>8</td>
                            </tr>
                            <tr style={{border: "none"}}>
                                <td style={{border: 'none'}}>Bob Brown</td>
                                <td style={{border: 'none'}}>12</td>
                            </tr>
                            <tr style={{border: "none"}}>
                                <td style={{border: 'none'}}>Charlie Davis</td>
                                <td style={{border: 'none'}}>7</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </>
    );
};

export default agentClientAssignment;