import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import Cookie from "../../../utils/cookie";
import HeaderNewLandingPage from "../HeaderNewLandingPage/HeaderNewLandingPage";
import {Alert, Button, Form, FormGroup} from "react-bootstrap";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FooterNewLandingPage from "../FooterNewLandingPage/FooterNewLandingPage";
import companyLogo from "./company-logo.svg";
import "./PartnershipPage.css";
import HeaderPartnerPage from "../HeaderNewLandingPage/HeaderPartnerPage";
import ic_users from "../../../assets/images/ic_users.png";
import Wishes_icon from "../../../assets/images/Wishes_icon.jpg";
import Password_icon from "../../../assets/images/password_incon.png";

const PartnershipPage = () => {
    const history = useHistory();
    const [username, setUsername] = useState(localStorage.getItem('email') !== null ? localStorage.getItem('email') : '');
    const [password, setPassword] = useState();
    const [companyId, setCompanyId] = useState();
    const [loginError, setEmailError] = useState(false);
    const [errormessage, setErrorMessage] = useState("");
    const [sDeviceID, setCurrentIp] = useState(null);
    const [hidden, setHidden] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("token"));
    const [userData, setUserData] = useState(null);
    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    const inputRef = useRef();
    console.log(userData)
    const isFormValid=username && password && companyId;
    useEffect(() => {
        getCurrentIp();
        profileDetailGet();
        if (inputRef.current) {
            inputRef.current.focus();
        }
        setErrorMessage("Wrong username/password");
    }, []);

    const profileDetailGet = () => {
        var token = localStorage.getItem("token");
        axios
            .get(process.env.REACT_APP_SERVER + "profile/detail", {
                headers: {Authorization: `Bearer ${token}`},
            })
            .then((res) => {
                if (res.data.status) {
                } else {
                    res &&
                    res.data &&
                    res.data.forEach((element) => {
                        setUserData(element);
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const submitClickBtn = (e) => {
        e.preventDefault();
        const data = {
            email: username,
            password: password,
            sDeviceID,
            sDeviceType: "WEB",
        };
        axios.post(process.env.REACT_APP_SERVER + "auth/login", data, {
            headers: {"Content-Type": "application/json",},
        }).then((res) => {
            Cookie.save("isFirstTime","1");
            document.cookie = `serviceplanid=${
                res.data.users.serviceplanid
            }; expires=${new Date(Date.now() + 10800000).toUTCString()}; path=/`;
            localStorage.setItem("dashboardLayout", res.data.users.checkinbuttononly);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("login", true);
            localStorage.setItem("userid", res.data.users.id);
            localStorage.setItem("email", res.data.users.email);
            localStorage.setItem("name", res.data.users.fname + ' ' + res.data.users.lname);
            localStorage.setItem("isLogin", 1);
            // const user = res.data.users
            // if (user.newuser === 1 && user.twofactorauth === 1) {
            //     localStorage.setItem('isAuthenticatedOTP', '0');
            //     localStorage.setItem('dashboardLayout', 0);
            // } else if (user.newuser === 1) {
            //     localStorage.setItem('isBtnClicked', false)
            //     localStorage.setItem('isAuthenticatedOTP', '1');
            //     localStorage.setItem('dashboardLayout', 1);
            // } else {
            //     localStorage.setItem('isAuthenticatedOTP', res.data.users.twofactorauth === 0 ? '1' : '0');
            // }
            // localStorage.setItem('servicePlanAttempts', 0);
            // history.push("/maindashboard");
            setIsLoggedIn(true);
        }).catch(() => {
            setEmailError(true);
            setErrorMessage("Wrong Username/Password");
        });
    };

    const getCurrentIp = () => {
        axios.get("https://geolocation-db.com/json/")
            .then((res) => {
                setCurrentIp(res.data.IPv4);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            {/*<HeaderNewLandingPage/>*/}
            <HeaderPartnerPage/>
            <div className="inroduction_heading">

                <div className="new_heading text-center ">
                    Your Company Name
                </div>

                <div className="d-flex justify-content-center mt-3">
                    <img src={companyLogo} alt="Company Logo" style={{ width: '90px', height: 'auto' }}/>
                </div>
            </div>
            {!isLoggedIn ? (<>
                    <div className=" d-flex justify-content-center align-items-center min-vh-100 mr-4 ">
                        <div className="signup_form login_form w-100 " style={{ maxWidth: '400px', width: '100%' }}>
                            <div className=" form_content " style={{marginRight:'-15px',marginTop:'-300px'}}>
                                <h2 className="form_title text-center">Login</h2>
                                <Form action="" onSubmit={submitClickBtn}>
                                    {loginError && (
                                        <Alert key="wrong-login" variant="danger">
                                            {errormessage}
                                        </Alert>
                                    )}
                                    <FormGroup controlId="exampleForm.ControlInput3">
                                        <Form.Control
                                            ref={inputRef}
                                            type="text"
                                            placeholder="Company ID"
                                            onChange={(e) => setCompanyId(e.target.value)}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup controlId="exampleForm.ControlInput3">
                                        <Form.Control
                                            ref={inputRef}
                                            type="email"
                                            placeholder="Email Address"
                                            onChange={(e) => setUsername(e.target.value)}
                                            required
                                        />
                                    </FormGroup>

                                    <FormGroup controlId="exampleForm.ControlInput3"
                                               className="position-relative">
                                        <Form.Control
                                            placeholder="Password"
                                            type={hidden ? "password" : "text"}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                        {hidden ? (
                                            <VisibilityOffIcon className="pass_show"
                                                               onClick={() => setHidden(!hidden)}/>
                                        ) : (
                                            <VisibilityIcon className="pass_show"
                                                            onClick={() => setHidden(!hidden)}/>
                                        )}
                                    </FormGroup>

                                    <p>
                                        <Link to={'/new-password'}>Forgot Password?</Link>
                                    </p>

                                    <Button
                                        variant="primary"
                                        disabled={!isFormValid}
                                        type="submit"
                                        className="btn-lg btn-block custom-btn"
                                    >
                                        <span>Login</span>
                                    </Button>
                                </Form>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="d-flex flex-column align-items-center py-3 pb-10 mt-4  bg-white border rounded" style={{
                    borderColor: 'gray',
                    maxWidth: '400px',
                    margin:'auto',
                    marginBottom: '50px',
                    boxShadow: '0 0px 40px rgba(141, 177, 184, 1)'
                }}>
                    {/* Buttons */}
                    <div className="w-95 px-3"> {/* Ensures buttons are constrained within the container */}
                        {userData && Boolean(userData.isExecutive) && (
                            <Button variant="light" size="lg"
                                    className="w-100 gap-2 px-3 py-2 my-1 d-flex align-items-center justify-content-start border rounded mb-3"
                                    style={{backgroundColor: 'white', borderColor: 'gray'}}>
                                <img src={ic_users} alt="Agents" className="me-2 rounded-circle"
                                     style={{width: '30', height: '30px', borderRadius: '50%'}}/>
                                <Link to="/agents" className="text-black text-decoration-none">Agents</Link>
                            </Button>
                        )}

                        <Button variant="light" size="lg"
                                className="w-100 gap-2 px-3 py-2 my-1 d-flex align-items-center justify-content-start border rounded mb-3"
                                style={{backgroundColor: 'white', borderColor: 'gray'}}>
                            <img src={Wishes_icon} alt="Clients" className="me-2 rounded-circle"
                                 style={{width: '30', height: '30px', borderRadius: '50%'}}/>
                            <Link to="/clients" className="text-black text-decoration-none">Clients</Link>
                        </Button>

                        <Button variant="light" size="lg"
                                className="w-100 gap-2 px-3 py-2 my-1 d-flex align-items-center justify-content-start border rounded mb-3"
                                style={{backgroundColor: 'white', borderColor: 'gray'}}>
                            <img src={ic_users} alt="Client Assignments"
                                 className="me-2 rounded-circle"
                                 style={{width: '30', height: '30px', borderRadius: '50%'}}/>
                            <Link to="/assignment" className="text-black text-decoration-none">Client
                                Assignments</Link>
                        </Button>

                        <Button variant="light" size="lg"
                                className="w-100 gap-2 px-3 py-2 my-1 d-flex align-items-center justify-content-start border rounded mb-3"
                                style={{backgroundColor: 'white', borderColor: 'gray'}}>
                            <img src={Wishes_icon} alt="Agents and Clients"
                                 className="me-2 rounded-circle"
                                 style={{width: '30', height: '30px', borderRadius: '50%'}}/>
                            <Link to="/agentsClientAssignment" className="text-black text-decoration-none">Agents and
                                Clients</Link>
                        </Button>

                        <Button variant="light" size="lg"
                                className="w-100 gap-2 px-3 py-2 my-1 d-flex align-items-center justify-content-start border rounded mb-3"
                                style={{backgroundColor: 'white', borderColor: 'gray'}}>
                            <img src={Password_icon} alt="Admin" className="me-2 rounded-circle"
                                 style={{width: '30', height: '30px', borderRadius: '50%'}}/>
                            <Link to="/admin" className="text-black text-decoration-none">Administration</Link>
                        </Button>
                    </div>
                </div>

            )}

            <div className="my-50"/>
            <FooterNewLandingPage/>
        </>
    );
};

export default PartnershipPage;